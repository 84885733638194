/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import Layout from '@lekoarts/gatsby-theme-cara/src/components/layout';
import MenuBar from '../@lekoarts/gatsby-theme-cara/components/menu-bar';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../@lekoarts/gatsby-theme-cara/constants/theme.js';
import SEO from '@lekoarts/gatsby-theme-cara/src/components/seo';
import BlogBackground from '../@lekoarts/gatsby-theme-cara/components/blog-background';
import GenericFooter from '../@lekoarts/gatsby-theme-cara/components/generic-footer';
import Fab from '@material-ui/core/Fab';
import { graphql, Link, navigate } from 'gatsby';
import Button from '@material-ui/core/Button';
import Img from 'gatsby-image';
import ContactForm from '../@lekoarts/gatsby-theme-cara/components/contact-form';
import Flavors from '../@lekoarts/gatsby-theme-cara/components/flavors';
import MVPSteps from '../@lekoarts/gatsby-theme-cara/components/mvp-steps';

const MVP = ({ data }) => {
  const [state, setState] = React.useState({ flavor: null, interest: null });
  const handleClick = update => {
    setState(prevState => ({
      ...prevState,
      ...update,
    }));
  };
  return (
    <ThemeProvider theme={theme}>
      <SEO title="MVP Development - From no-code to full-code" />
      <Layout className="Blog-post-page">
        <MenuBar />
        <BlogBackground offset={0} />
        <div className="Pricing-content text-center">
          <MVPSteps data={data} />
        </div>
        <GenericFooter />

        <form name="quote" data-netlify="true" data-netlify-honeypot="bot-field" hidden>
          <input type="text" name="company" />
          <input type="text" name="firstName" />
          <input type="text" name="lastName" />
          <input type="email" name="email" />
          <input type="phone" name="phone" />
          <textarea name="message" />
          <input type="checkbox" name="solicit" />
        </form>
      </Layout>
    </ThemeProvider>
  );
};

export default MVP;

export const coneImage = graphql`
  fragment coneImage on File {
    childImageSharp {
      fixed(height: 120) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`;

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "Coffee.png" }) {
      ...coneImage
    }

    image2: file(relativePath: { eq: "MintChocolateChip.png" }) {
      ...coneImage
    }

    image3: file(relativePath: { eq: "CaramelSwirl.png" }) {
      ...coneImage
    }

    image4: file(relativePath: { eq: "BlackCherry.png" }) {
      ...coneImage
    }

    image5: file(relativePath: { eq: "RockyRoad.png" }) {
      ...coneImage
    }

    image6: file(relativePath: { eq: "Peppermint.png" }) {
      ...coneImage
    }
  }
`;
