/** @jsx jsx */
import { jsx } from 'theme-ui';
import Img from 'gatsby-image';
import Fab from '@material-ui/core/Fab';
import BookCall from './book-call';
import useScrollStart from './use-scroll-start';
import MVPDevelopment from '../sections/mvpdevelopment.mdx';
import { UpDown, UpDownWide } from '@lekoarts/gatsby-theme-cara/src/styles/animations';
import SVG from '@lekoarts/gatsby-theme-cara/src/components/svg';
import progress from '../../../../static/progress.png';
import LightningBolt from '@material-ui/icons/Speed';
import Construction from '@material-ui/icons/Build';
import Currency from '@material-ui/icons/Payment';
import Scale from '@material-ui/icons/AspectRatio';
import ArrowDownward from '@material-ui/icons/ArrowDownwardRounded';
import ArrowRightRounded from '@material-ui/icons/ArrowRight';

const MVPSteps = ({ data }) => {
  const isScrolling = useScrollStart();

  return (
    <div>
      <div className="hero">
        <h1 sx={{ fontSize: ['1.8em', '1.8em', '3em'], lineHeight: '1.1em' }}>
          <span className={isScrolling ? 'minimum-final' : 'minimum-orig'}>Minimum </span>
          <div
            sx={{
              position: 'relative',
              width: ['150px', '150px', '230px'],
              display: 'inline-block',
            }}
          >
            <span
              sx={{
                marginLeft: ['-38px', '-38px', '-28px'],
                fontSize: ['inherit', 'inherit', '1.2em'],
              }}
              className={isScrolling ? 'hero--animate1' : 'hero--text1'}
            >
              Viable
            </span>
            <span className={isScrolling ? 'hero--animate2' : 'hidden-element'}>
              Loveable
            </span>
          </div>{' '}
          Product <br /> development reimagined
        </h1>
        <h3 sx={{ fontWeight: '300', fontSize: ['0.9em', '0.9em', '1.1em'] }}>
          Unlock your startup's potential with our 90-day go-to-market MVP build
          membership! 🚀 <br />
          Speed up success, reduce costs, and skyrocket your vision by subscribing today.
        </h3>
        <div sx={{ marginTop: '20px' }}>
          <Fab
            color="primary"
            onClick={() =>
              document
                ?.getElementById('twin-section')
                ?.scrollIntoView({ behavior: 'smooth' })
            }
            variant="extended"
            sx={{ marginTop: '20px', backgroundColor: '#6ffe39' }}
          >
            See plan <ArrowDownward fontSize="small" />
          </Fab>
        </div>
      </div>
      <div className="attention-card">
        {/* <SVG icon="cone" width={24} color="#6FFFE9" left="-2%" top="-25%" sx={{ transform: "rotate(30deg)" }} /> */}

        <UpDownWide>
          <SVG icon="spiral" width={24} stroke color="icon_pink" left="15%" top="15%" />
        </UpDownWide>
        <UpDown>
          <SVG icon="triangle" width={6} color="icon_blue" stroke left="75%" top="10%" />
        </UpDown>
        <div sx={{ zIndex: '999', position: 'relative' }}>
          <h2 sx={{ fontSize: ['2rem'], lineHeight: ['1.2em'] }}>
            Taste the twist, project bliss ✨
          </h2>
          <h3 sx={{ fontWeight: '300', padding: ['0', '0', '0 180px'] }}>
            Swirl replaces unreliable freelance developers and shady overseas dev shops
            with one flat monthly fee, and a rapid 90-day development cycle.
          </h3>
        </div>
      </div>
      <h1 sx={{ paddingTop: '50px' }} className="large">
        Membership Benefits
      </h1>
      <div className="benefit-grid">
        <div>
          <LightningBolt fontSize="large" />
          <h4>Top speed delivery</h4>
          <p>Our 90-day development cycle is one of the fastest in the industry</p>
        </div>
        <div>
          <Construction fontSize="large" />
          <h4>Feature Board</h4>
          <p>Add as many feature requests to your board as you'd like</p>
        </div>
        <div>
          <Currency fontSize="large" />
          <h4>Fixed monthly rate</h4>
          <p>Pay the same price each month, for three months per project</p>
        </div>
        <div>
          <Scale fontSize="large" />
          <h4>Scalable and Flexible</h4>
          <p>Scale the project up if needed and pause or cancel at any time.</p>
        </div>
      </div>
      <h1 sx={{ paddingTop: '50px' }} className="large">
        Our Go-To-Market Process
      </h1>
      <div
        id="mvp-steps"
        sx={{
          display: `grid`,
          gridGap: [4, 4, 4, 4],
          gridTemplateColumns: [`1fr`, `1fr`, `repeat(2, 1fr)`, `repeat(3, 1fr)`],
          h2: { gridColumn: `-1/1`, color: `white !important` },
          button: {
            height: '630px',
            fontSize: '27px',
            boxShadow: 'inset 0px 0px 10px 0px rgba(0, 0, 0, 0.35)',
            dropShadow: '1px 1px white',
          },
          padding: '2em 0',
        }}
      >
        {/* Step 1: Fab Card */}
        <Fab
          variant="extended"
          id="peppermint"
          onClick={() =>
            document.getElementById('roadmap')?.scrollIntoView({ behavior: 'smooth' })
          }
        >
          <div>
            Step 1<h2 sx={{ margin: 0, fontSize: '50px' }}>🗞️</h2>
            <p
              sx={{
                fontSize: '22px',
                fontWeight: 'bold',
                marginTop: '-5px',
              }}
            >
              The Roadmap
            </p>
            <p
              sx={{
                paddingBottom: '10px',
                fontSize: ['14px', '14px', '18px'],
              }}
            >
              Validate the problem you want to solve with actual users
            </p>
            <div
              className="MVP-list-container"
              sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
            >
              <p
                sx={{
                  fontSize: ['14px', '14px', '16px'],
                  margin: '20px 0 20px',
                }}
                className="first-list-item"
              >
                At the beginning of our journey together, we'll kick off with a delightful
                Product Roadmapping experience. We'll deliver all your project
                specifications with thorough and easy-to-follow documentation!
              </p>

              <p
                sx={{
                  fontSize: ['14px', '14px', '16px'],
                }}
                className="second-list-item"
              >
                This will provide you with crystal-clear understanding of the problem at
                hand, your product's uniqueness, its perfect positioning, our tailored
                solutions, and a comprehensive overview of the costs and timeline
                involved.
              </p>
            </div>
          </div>
        </Fab>
        {/* Step 2: Fab Card */}
        <Fab
          variant="extended"
          id="integrated"
          onClick={() =>
            document.getElementById('prototype')?.scrollIntoView({ behavior: 'smooth' })
          }
        >
          <div>
            Step 2<h2 sx={{ margin: 0, fontSize: '50px' }}>💨</h2>
            <p
              sx={{
                fontSize: '22px',
                fontWeight: 'bold',
                marginTop: '-5px',
              }}
            >
              The Prototype
            </p>
            <p
              sx={{
                paddingBottom: '10px',
                fontSize: ['14px', '14px', '18px'],
              }}
            >
              {' '}
              Rapidly iterate while collecting as much feedback as possible
            </p>
            <div
              className="MVP-list-container"
              sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
            >
              <p
                sx={{
                  fontSize: ['14px', '14px', '16px'],
                  margin: '20px 0 0',
                }}
                className="first-list-item"
              >
                After we've embarked on our exhilarating Product Roadmapping journey, it's
                time to fast-track our way to the Rapid Prototyping phase! Here, we'll
                take our newfound understanding and unleash our creative prowess to
                quickly bring your vision to life.
              </p>
              <p
                sx={{
                  fontSize: ['14px', '14px', '16px'],
                  margin: 0,
                }}
                className="second-list-item"
              >
                And if you've already got a roadmap and designs from elsewhere, no
                problem! We'd be over the moon to join forces and help you accelerate
                through the Rapid Prototyping stage.
              </p>
            </div>
          </div>
        </Fab>

        {/* Step 3: Fab Card */}
        <Fab
          variant="extended"
          id="onlineorder"
          onClick={() =>
            document.getElementById('mvp')?.scrollIntoView({ behavior: 'smooth' })
          }
        >
          <div>
            Step 3<p sx={{ margin: 0, fontSize: '50px' }}>🚀</p>
            <p
              sx={{
                fontSize: '22px',
                fontWeight: 'bold',
                marginTop: '-5px',
              }}
            >
              The MVP
            </p>
            <p
              sx={{
                paddingBottom: '10px',
                fontSize: ['14px', '14px', '18px'],
              }}
            >
              With a solid plan and prototype in place, start building the MVP
            </p>
            <div
              className="MVP-list-container"
              sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
            >
              <p
                sx={{
                  fontSize: ['14px', '14px', '16px'],
                  marginBottom: 0,
                }}
                className="first-list-item"
              >
                Once we've navigated through the thrilling preliminary phases together,
                it's time to buckle up for the grand finale! Armed with our deep
                understanding and unparalleled expertise, we'll work our magic to turn
                your vision into a spectacular reality.{' '}
              </p>
              <p
                sx={{
                  fontSize: ['14px', '14px', '16px'],
                  margin: 0,
                }}
                className="second-list-item"
              >
                And if you've already completed the earlier stages elsewhere, no worries
                at all! We'd be delighted to jump on board and collaborate with you to
                bring your remarkable project to the finish line.
              </p>
            </div>
          </div>
        </Fab>
      </div>
      <div id="twin-section" className="attention-card split">
        <div className="attention-card--image">
          <img src={progress} />
        </div>
        <div>
          <h3>Bye to Meetings 👋</h3>
          <p>Meetings are a thing of the past. We're fully async.</p>
          <h3>Manage with Notion 🧠</h3>
          <p>
            View active, queued and completed tasks within your own project Notion board
            with ease.
          </p>
          <h3>Weekly Updates ⏳</h3>
          <p>Get weekly updates on the progress of your project</p>
        </div>
      </div>
      {/* <div className="styled-markdown">
        <MVPDevelopment />
      </div> */}
      <h1 sx={{ paddingTop: '50px' }} className="large">
        Membership Plan
      </h1>

      <div id="buy-now" className="attention-card tight">
        {/* <SVG icon="cone" width={24} color="#6FFFE9" left="-2%" top="-25%" sx={{ transform: "rotate(30deg)" }} /> */}

        <UpDownWide>
          <SVG icon="cone" width={24} color="#6FFFE9" left="-5%" top="15%" />
        </UpDownWide>
        <UpDown>
          <SVG icon="triangle" width={6} color="icon_blue" stroke left="75%" top="10%" />
        </UpDown>
        <div sx={{ zIndex: '999', position: 'relative' }}>
          <h2 sx={{ fontSize: ['2rem'], lineHeight: ['1.2em'] }}>Monthly</h2>
          <p sx={{ fontWeight: '300' }}>
            There is no minimum commitment. Pause or cancel at any time if you are
            unsatisfied.
          </p>
          <h2>$14,955/mo</h2>
          <Fab
            color="primary"
            href="https://buy.stripe.com/7sIdT30PweeCbWU144"
            variant="extended"
            sx={{ backgroundColor: '#6ffe39', marginTop: '40px' }}
          >
            Get started <ArrowRightRounded />
          </Fab>
        </div>
      </div>
    </div>
  );
};

export default MVPSteps;
