import { useState, useEffect } from 'react';

function useScrollStart() {
  const [scrollStarted, setScrollStarted] = useState(false);

  useEffect(() => {
    const onScrollOrTouchMove = () => {
      if (!scrollStarted) {
        setScrollStarted(true);
      }
    };

    window.addEventListener('scroll', onScrollOrTouchMove);
    window.addEventListener('touchmove', onScrollOrTouchMove);

    return () => {
      window.removeEventListener('scroll', onScrollOrTouchMove);
      window.removeEventListener('touchmove', onScrollOrTouchMove);
    };
  }, [scrollStarted]);

  return scrollStarted;
}

export default useScrollStart;
